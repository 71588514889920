import {Component, ElementRef, Inject, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {LoadingService} from './block/interceptor/loading.service';
import {DeviceDetectorService} from 'ngx-device-detector';

import 'core-js/es/string';
import 'core-js/es/array';
import 'core-js/es/map';
import {JhiEventManager} from 'ng-jhipster';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {} from './app.constants'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

  @ViewChild('template')
  public template: ElementRef;
  modalRef: BsModalRef;
  @Output() eventManager = new JhiEventManager;

  constructor(
    private loadingService: LoadingService,
    private deviceService: DeviceDetectorService,
    private modalService: BsModalService,

  ) {

  }

  ngOnInit() {
    const browser = this.deviceService.getDeviceInfo();
    const version = parseInt(browser.browser_version, 10);

    console.log(browser.browser);
    console.log(browser.browser_version);
    console.log(version);
    let ok = false;

    if (browser.browser === 'chrome' || browser.browser === 'Chrome') {
      // ok = version >= 45;
      ok = version >= 45;
    } else if (browser.browser === 'firefox' || browser.browser === 'Firefox') {
      ok = version >= 38;
    } else if (browser.browser === 'safari' || browser.browser === 'Safari') {
      ok = version >= 9;
    } else if (browser.browser === 'opera' || browser.browser === 'Opera') {
      ok = version >= 30;
    } else if (browser.browser === 'edge') {
      ok = version >= 12;
    } else if (browser.browser === 'ms-edge-chromium' || browser.browser === 'MS-Edge-Chromium') {
      ok = true;
    }
    console.log(ok);
    if(!ok) {
      this.openModal();
    }
  }

  openModal() {
    this.modalRef = this.modalService.show(this.template, {class: 'modal-sm', backdrop: true, ignoreBackdropClick: true,});
  }

  isLoading() {
    return this.loadingService.isLoading();
  }
}
