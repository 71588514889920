import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable, of} from 'rxjs';

@Injectable()
export class NewsService {
  private resourceUrl = environment.apiUrl + 'news';
  private resourceUrlCat = environment.apiUrl + 'category';

  constructor(private http: HttpClient) {
  }

  getAllNews(mock?: boolean): Observable<any[]> {
    if (mock) {
      return of(this.getAllProjectsMock());
    }
    return this.http.get<any[]>(this.resourceUrl + '/list');
  }

  getNews(id: number): Observable<any> {
    return this.http.get<any>(this.resourceUrl + '/' + id);
  }

  getNewsHeader(id: number): Observable<any> {
    return this.http.get<any>(this.resourceUrl + '/' + id + '/header/');
  }

  save(request: any): Observable<any> {
    return this.http.post<any>(this.resourceUrl, request);
  }

  delete( id: number): Observable<any> {
    return this.http.delete<any>(this.resourceUrl + '/' + id, {});
  }

  getAllCategory(): Observable<any[]> {
    return this.http.get<any[]>(this.resourceUrlCat + '/list');
  }

  getCategory(id: number): Observable<any> {
    return this.http.get<any>(this.resourceUrlCat + '/' + id);
  }

  saveCategory(request: any): Observable<any> {
    return this.http.post<any>(this.resourceUrlCat, request);
  }

  deleteCategory( id: number): Observable<any> {
    return this.http.delete<any>(this.resourceUrlCat + '/' + id, {});
  }

  private getAllProjectsMock() {
    return [
      {
        id: 1,
        name: 'Project 1',
        type: 'The cage',
      },
  ]};
}
