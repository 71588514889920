import {Component} from '@angular/core';
import {ICellEditorAngularComp, ICellRendererAngularComp} from 'ag-grid-angular';

@Component({
  selector: 'app-alert-renderer-cell',
  templateUrl: './popover.component.html'
})
export class AlertPopoverComponent implements ICellEditorAngularComp, ICellRendererAngularComp {
  params: any;

  agInit(params: any): void {
    this.params = params;
  }

  // demonstrates how you can do "inline" editing of a cell
  onChange($event) {
  }

  getValue() {
    if (this.params.data.received === true || this.params.data.received === 1) {
      return 'SCADENZA';
    } else if (this.params.data.received === false || this.params.data.received === null) {
      return 'FORMAZIONE';
    } else if (this.params.data.error === true || this.params.data.error === 1) {
      return 'ACCESSO';
    }
  }

  getClass() {
    if (!this.params.node.group) {
      let className = 'btn-table btn-block btn-raised btn btn-';
      if (this.params.data.received === true || this.params.data.received === 1) {
        return className += 'danger'
      } else if (this.params.data.received === false || this.params.data.received === null) {
        return className += 'warning';
      } else if (this.params.data.error === true || this.params.data.error === 1) {
        return className += 'warning';
      }
    }
  }

  refresh(params: any): boolean {
    return false;
  }

  getPopoverTemplate(states: any[]) {

    let template = '';
    states.forEach(state => {
      template += '<div>' + state.processStatus + '</div>';
    });
    return template;
  }
}
