import {RouteInfo} from './sidebar.metadata';
import {ADMIN, EDITOR, NEWTON, RESPONSABILE, SUPER_ADMIN} from '../../app.constants';

export const ROUTES: RouteInfo[] = [
  /*{
    path: '/dashboard',
    title: 'Dashboard',
    icon: 'icon-home',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    permission: [ADMIN,SUPER_ADMIN,RESPONSABILE,NEWTON]
  },*/
  {
    path: '/partecipanti',
    title: 'Partecipanti',
    icon: 'icon-participants',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    permission: [ADMIN,SUPER_ADMIN,RESPONSABILE,NEWTON]
  },
  /*{
    path: '/corsi',
    title: 'Programmi',
    icon: 'icon-academy',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    permission: [ADMIN,SUPER_ADMIN,NEWTON]
  },
  {
    path: '/sezioni',
    title: 'Corsi',
    icon: 'icon-sections',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    permission: [ADMIN,SUPER_ADMIN,NEWTON]
  },*/
  {
    path: '/media-library',
    title: 'Media Library',
    icon: 'icon-video',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    permission: [ADMIN,SUPER_ADMIN,NEWTON,RESPONSABILE,EDITOR]
  },
  /*{
    path: '/quiz',
    title: 'Quiz',
    icon: 'icon-flag',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    permission: [ADMIN,SUPER_ADMIN,NEWTON]
  },*/
  {
    path: '/news',
    title: 'News',
    icon: 'icon-news',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    permission: [ADMIN,SUPER_ADMIN,RESPONSABILE,EDITOR]
  },
  /*{
    path: '/agora',
    title: 'Agorà',
    icon: 'icon-agora',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    permission: [ADMIN,SUPER_ADMIN,RESPONSABILE]
  },
  {
    path: '/ruoli',
    title: 'Ruoli',
    icon: 'icon-settings',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    permission: [ADMIN,SUPER_ADMIN]
  },
  {
    path: '/farmacie',
    title: 'Farmacie',
    icon: 'icon-pin',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    permission: [ADMIN,SUPER_ADMIN,RESPONSABILE]
  },*/
  {
    path: '/address',
    title: 'Indirizzi',
    icon: 'icon-pin',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    permission: [ADMIN,SUPER_ADMIN,RESPONSABILE]
  },
  {
    path: '/user',
    title: 'Utenti',
    icon: 'icon-chat',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    permission: [ADMIN,SUPER_ADMIN]
  },
];
