import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable, of} from 'rxjs';

@Injectable()
export class PartecipantService {
  private resourceUrl = environment.apiUrl + 'partecipant';

  constructor(private http: HttpClient) {
  }

  getAllPartecipants(mock?: boolean): Observable<any[]> {
    if (mock) {
      return of(this.getAllProjectsMock());
    }
    return this.http.get<any[]>(this.resourceUrl + '/list');
  }

  getPartecipant(id: number): Observable<any> {
    return this.http.get<any>(this.resourceUrl + '/' + id);
  }

  getPartecipantAttributeType(): Observable<any[]> {
    return this.http.get<any[]>(environment.apiUrl + 'partecipantAttributeType/list');
  }

  getOffice(): Observable<any[]> {
    return this.http.get<any[]>(environment.apiUrl + 'office/list');
  }

  getPartecipantHeader(id: number): Observable<any> {
    return this.http.get<any>(this.resourceUrl + '/' + id + '/header/');
  }

  invitePartecipant(request: any){
    return this.http.post<any>(this.resourceUrl + '/invite', request);
  }

  save(request: any): Observable<any> {
    return this.http.post<any>(this.resourceUrl, request);
  }

  delete( id: number): Observable<any> {
    return this.http.delete<any>(this.resourceUrl + '/' + id, {});
  }

  uploadExcelPartecipant(file: File, idEvent: number): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    return this.http.post<any[]>(this.resourceUrl + '/' + idEvent + '/load', formData, {observe: 'response'});
  }

  downloadPartecipantExcel(): Observable<Blob> {
    return this.http.get(this.resourceUrl + '/import/template', {responseType: 'blob'});
  }

  private getAllProjectsMock() {
    return [
      {
        id: 1,
        name: 'Project 1',
        type: 'The cage',
      },
  ]};
}
