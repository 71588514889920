import { Component } from "@angular/core";
import {ICellRendererAngularComp} from 'ag-grid-angular';

@Component({
  selector: 'app-image-formatter-cell',
  template: `<img alt="thumb" width="48" height="48" style="padding: 4px; object-fit: contain;" src=\"{{ params.value }}\">`
})

export class ImageRenderer implements ICellRendererAngularComp{
  params: any;


  agInit(params: any): void {
    this.params = params;
    if(this.params.value == null){
      this.params.value = "assets/img/farmagora/Placeholder-Farmagora-800x600.jpg"
    }else{
      if(this.params.data.mediaTypeName != "Immagine"){
        this.params.value = "assets/img/farmagora/Placeholder-Farmagora-800x600.jpg"
      }
      if(this.params.data.mediaTypeName == "Pdf"){
        this.params.value = "assets/img/farmagora/PDF-48x48.png"
      }
      if(this.params.data.mediaTypeName == "Power Point"){
        this.params.value = "assets/img/farmagora/PPT-48x48.png"
      }
    }
  }


  refresh(): boolean {
    return false;
  }

}
