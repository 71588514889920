<nav class="navbar navbar-expand-md navbar-dark bg-faded">
  <div class="container-fluid">
    <div class="navbar-header">
      <!--<div  class="navbar-toggle menu-toggle d-xl-none d-block float-left align-items-center justify-content-center"  data-toggle="collapse">
        <i class="ft-menu font-medium-3"></i>
      </div>-->
      <ul class="navbar-nav">
        <li class="nav-item mr-2 d-none d-lg-block">
          <a class="nav-toggle nav-link" id="sidebarToggle" href="javascript:;"
             (click)="toggleSidebar()">
            <i [ngClass]="{
          'icon-sidebar-closed': config.layout.sidebar.collapsed,
          'icon-sidebar-open': !config.layout.sidebar.collapsed
        }" class="toggle-icon font-medium-3"></i>
          </a>
        </li>
        <li class="nav-item mr-2 d-none d-lg-block">
          <a id="navbar-fullscreen" (click)="Fullscreen()" class="nav-link">
            <i *ngIf="!isFullscreen" class="font-medium-3 icon-fullscreen-on"></i>
            <i *ngIf="isFullscreen" class="font-medium-3 icon-fullscreen-off"></i>
          </a>
        </li>
      </ul>
    </div>
    <div class="navbar-container">
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav">
         <li class="nav-item">
            <span class="nav-link"  *ngIf="!isLoginAsLogout()">
             {{getNavbarUserString()}}
            </span>
           <a class="nav-link"  *ngIf="isLoginAsLogout()" (click)="loginAs(0)">
             {{getNavbarUserString()}}
           </a>
         </li>
          <li class="nav-item">
            <a class="nav-link" (click)="logout()" title="Logout">
              <i class="icon-logout mr-2 font-medium-3 pr-4"></i>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</nav>
