import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';

@Injectable()
export class AuthServerProvider {
  constructor(
    private http: HttpClient,
    private $localStorage: LocalStorageService,
    private $sessionStorage: SessionStorageService,
  ) {
  }

  getToken() {

    return this.$localStorage.retrieve('authenticationToken') || this.$sessionStorage.retrieve('authenticationToken');
  }

  login(credentials): Observable<any> {

    const data = {
      username: credentials.username,
      password: credentials.password,
      // rememberMe: true
    };
    return this.http.post(environment.apiUrl + 'auth/login', data, {observe: 'response'}).pipe(map(authenticateSuccess.bind(this)));

    function authenticateSuccess(resp: HttpResponse<any>) {
      const bearerToken = resp.headers.get('Authorization');
      if (bearerToken && bearerToken.slice(0, 7) === 'Bearer ') {
        const jwt = bearerToken.slice(7, bearerToken.length);
        this.storeAuthenticationToken(jwt, true);
        return resp.body;
      }
    }
  }

  loginAs(id?: number) {
    return this.http.get(environment.apiUrl + 'auth/login/as/' + id, {observe: 'response'}).pipe(map(authenticateSuccess.bind(this)));

    function authenticateSuccess(resp) {
      const bearerToken = resp.headers.get('Authorization');
      if (bearerToken && bearerToken.slice(0, 7) === 'Bearer ') {
        const jwt = bearerToken.slice(7, bearerToken.length);
        this.storeAuthenticationToken(jwt, true);
        this.storeRefreshUser(true);
        return resp.body;
      }
    }
  }

  loginWithToken(jwt, rememberMe) {
    if (jwt) {
      this.storeAuthenticationToken(jwt, rememberMe);
      return Promise.resolve(jwt);
    } else {
      return Promise.reject('auth-jwt-service Promise reject'); // Put appropriate error message here
    }
  }

  initResetPassword(body: any): Observable<any> {
    return this.http.post<any>(environment.apiUrl + 'user/reset_password/', body);
  }
  finishResetPassword(body: any): Observable<any> {
    return this.http.post<any>(environment.apiUrl + 'user/profile/changePassword', body);
  }

  getProfile(): Observable<any> {
    return this.http.get<any>(environment.apiUrl + 'user/profile');
  }

  storeRefreshUser(value: boolean){
    this.$localStorage.store('authenticationTokenRefresh', value);
    // } else {
    this.$sessionStorage.store('authenticationTokenRefresh', value);
  }
  storeAuthenticationToken(jwt, rememberMe) {
    // if (rememberMe) {
    this.$localStorage.store('authenticationToken', jwt);
    // } else {
    this.$sessionStorage.store('authenticationToken', jwt);
    // }
  }

  logout(): Observable<any> {
    return new Observable((observer) => {
      this.$localStorage.clear('authenticationToken');
      this.$sessionStorage.clear('authenticationToken');
      observer.complete();
    });
  }
}
