import { Routes, RouterModule } from '@angular/router';

//  Route for content layout with sidebar, navbar and footer
// loadChildren: () => import('../../pages/content-pages/content-pages.module').then(m => m.ContentPagesModule)
export const FULL_ROUTES: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('../modules/dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'user',
    loadChildren: () => import('../modules/user/user.module').then(m => m.UserModule)
  },
  {
    path: 'client',
    loadChildren: () => import('../modules/client/client.module').then(m => m.ClientModule)
  },
  {
    path: 'corsi',
    loadChildren: () => import('../modules/course/course.module').then(m => m.CourseModule)
  },
  {
    path: 'partecipanti',
    loadChildren: () => import('../modules/partecipant/partecipant.module').then(m => m.PartecipantModule)
  },
  {
    path: 'ruoli',
    loadChildren: () => import('../modules/partecipant-role/partecipant-role.module').then(m => m.PartecipantRoleModule)
  },
  {
    path: 'farmacie',
    loadChildren: () => import('../modules/pharmacy/pharmacy.module').then(m => m.PharmacyModule)
  },
  {
    path: 'quiz',
    loadChildren: () => import('../modules/quiz/quiz.module').then(m => m.QuizModule)
  },
  {
    path: 'sezioni',
    loadChildren: () => import('../modules/section/section.module').then(m => m.SectionModule)
  },
  {
    path: 'media-library',
    loadChildren: () => import('../modules/media-library/media-library.module').then(m => m.MediaLibraryModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('../modules/partecipant-settings/partecipant-settings.module').then(m => m.PartecipantSettingsModule)
  },
  {
    path: 'unit',
    loadChildren: () => import('../modules/unit/unit.module').then(m => m.UnitModule)
  },
  {
    path: 'news',
    loadChildren: () => import('../modules/news/news.module').then(m => m.NewsModule)
  },
  {
    path: 'agora',
    loadChildren: () => import('../modules/agora/agora.module').then(m => m.AgoraModule)
  },
  {
    path: 'address',
    loadChildren: () => import('../modules/address/address.module').then(m => m.AddressModule)
  },
];
