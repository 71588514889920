import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable, of} from 'rxjs';

@Injectable()
export class QuizService {
  private resourceUrl = environment.apiUrl + 'quiz';

  constructor(private http: HttpClient) {
  }

  getAllQuiz(): Observable<any[]> {
    return this.http.get<any[]>(this.resourceUrl + '/list');
  }

  getQuiz(id: number): Observable<any> {
    return this.http.get<any>(this.resourceUrl + '/' + id);
  }

  getQuizHeader(id: number): Observable<any> {
    return this.http.get<any>(this.resourceUrl + '/' + id + '/header/');
  }

  save(request: any): Observable<any> {
    return this.http.post<any>(this.resourceUrl, request);
  }

  delete( id: number): Observable<any> {
    return this.http.delete<any>(this.resourceUrl + '/' + id, {});
  }

}
